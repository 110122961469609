body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .mobileFooter {
    @apply w-full bg-[#f5f5f5] ;
  }
  .footer {
    @apply fixed bottom-0 left-0 w-full bg-[#f5f5f5];
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;